<template>
  <div  style="padding: 20px">
    <!-- 面包屑 -->
    <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农识资讯</el-breadcrumb-item>
      <el-breadcrumb-item>农识资讯详情</el-breadcrumb-item>
      <a @click="goBack" href="#" style="color: red">
        <span>返回上一级</span>
      </a>
    </el-breadcrumb>

    </div>


    <a-card>
      <a-row>
        <a-col :lg="{span: 24}" :xxl="{span: 24}">
          <div>
            <h1 class="detail-title">{{ queryItem.title }}</h1>
            <p class="detail-title-sub">
              <span style="margin-right: 40px;">编辑：{{ queryItem.author }}</span>
              <span>时间：{{ queryItem.time | dateFormatFull}}</span>
            </p>
          </div>
          <!-- 图片和视频 -->
          <div class="file-wrapper">
            <div v-if="isShowImg" class="img-wrapper">
              <img :src="queryItem.imgPathAll" alt="">
            </div>
            <div v-if="isShowVideo" class="video-wrapper">
              <video :src="queryItem.videoPathAll" controls="controls"></video>
            </div>
          </div>
          <!-- 正文 -->
          <div>
            <p class="detail-content">{{ queryItem.text }}</p>
          </div>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
export default {
  name: "AgriculturalDetail",

  data() {
    return {
      queryItem: {}
    }
  },
  mounted() {
    this.queryItem = JSON.parse(this.$route.query.item)
  },

  computed: {
    //判断是否显示Logo
    isShowImg() {
      if (this.$route.query.item.imgPathAll === "") {
        return false;
      } else {
        const reg = /null/;
        return !reg.test(this.$route.query.item.imgPathAll)

      }

    },
    isShowVideo() {
      if (this.queryItem.videoPathAll === "") {
        return false;
      } else {
        const reg = /null/;
        return !reg.test(this.queryItem.videoPathAll)
      }
    }
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    }
  },
}
</script>

<style scoped>

.detail-title {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}

.detail-title-sub {
  text-align: center;
  font-size: 14px;
  color: #4c4c4c;
}

.img-wrapper {
  width: 350px;
  margin: 30px auto;
}

.img-wrapper img {
  width: 100%;
}

.video-wrapper {
  width: 450px;
  margin: 20px auto;
}

.video-wrapper video {
  width: 100%;
}

.detail-content {
  margin: 20px 90px;
  line-height: 25px;
  font-size: 15px;
  text-indent:2em;
}
</style>
